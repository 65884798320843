var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quota" },
    [
      _c("div", { staticClass: "quota-content" }, [
        _c("div", { class: ["quota__main", { actual: _vm.isActualNow }] }, [
          _c("div", [
            _c("span", { staticClass: "title__description" }, [
              _vm._v(" Культура: "),
              _c("b", [_vm._v(_vm._s(_vm.getQuotaCulture))]),
            ]),
            _c("span", { staticClass: "title__description" }, [
              _vm._v(" // Кол-во в буфере: "),
              _c("b", { staticClass: "red" }, [
                _vm._v(_vm._s(_vm.getBufferQuota)),
              ]),
            ]),
          ]),
          !_vm.isMobile
            ? _c(
                "div",
                {
                  class: [
                    "quota-content__icon-wrap",
                    { exporter: _vm.isExporter },
                    { support: _vm.isSupport || _vm.isViewer },
                  ],
                },
                [
                  !_vm.isSupport && !_vm.isViewer
                    ? _c("Icon", {
                        attrs: {
                          name: "IconPen",
                          width: "20px",
                          height: "18px",
                        },
                        on: { onClick: _vm.handleEditQuota },
                      })
                    : _vm._e(),
                  !_vm.isExporter && !_vm.isSupport && !_vm.isViewer
                    ? _c("Icon", {
                        attrs: {
                          name: "IconTrash",
                          width: "15px",
                          height: "18px",
                        },
                        on: { onClick: _vm.handleDeleteQuota },
                      })
                    : _vm._e(),
                  _c("Icon", {
                    class: ["arrow", { rotated: _vm.isOpen }],
                    attrs: {
                      name: "ArrowDownIcon",
                      width: "22px",
                      height: "22px",
                    },
                    on: {
                      onClick: function ($event) {
                        _vm.isOpen = !_vm.isOpen
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "el-collapse-transition",
        [
          _c("SampleDetail", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen && !_vm.isMobile,
                expression: "isOpen && !isMobile",
              },
            ],
            attrs: { quota: _vm.quota },
          }),
        ],
        1
      ),
      _c("QuotaDeleteModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }